import { IFinancialsCOATableRow } from '../components/helpers';
import { formatterDollarUSNoDecimal, formatterPercentWithSign } from '../../../utils/formatters';
import { Property } from '../../../contexts/properties/PropertiesContext';
import { chunkArray } from './pdfExportHelpers';


function isAllZeroOrNull(values: (number | null)[]): boolean {
    return values.every(value => value === 0 || value === null);
}

export function financialTableDataToRows(property: Property, tableData: IFinancialsCOATableRow[]): string[][][] {
    const columnHeaders = [
        "GL No.",
        "Description",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        `${property.budgetYear} BDGT Total`,
        `${property.reforecastYear} RFCST Total`,
        "Var $",
        "Var %",
    ];

    const rows = tableData
        .filter(row => !isAllZeroOrNull(row.budgetValues) || !isAllZeroOrNull(row.reforecastValues))
        .sortBy("order").map(row => [
            row.number,
            row.name,
            ...row.budgetValues.map(val => val === null ? "-" : formatterDollarUSNoDecimal.format(val)),
            row.budgetTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetTotal),
            row.reforecastTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.reforecastTotal),
            row.varianceAmount === null ? "-" : formatterDollarUSNoDecimal.format(row.varianceAmount),
            row.variancePercent === null ? "-" : formatterPercentWithSign.format(row.variancePercent),
    ]);

    const chunkedData = chunkArray(rows, 28).map(chunk => [columnHeaders, ...chunk]);

    return chunkedData;
}
