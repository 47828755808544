import { Document, Page, Text, View, Image, pdf, PDFViewer, Font, Link } from '@react-pdf/renderer';
import { PropertyExecutiveSummaryData, SERIES_DATA_OPTION } from "../logic/usePropertyExecutiveSummaryData";
import "native-injects";
import brandLight from "../../../assets/icons/brand/brand_light.png";
import brand from "../../../assets/icons/brand/brand.png";
import addRounded from "../../../assets/images/executive_summary/add_rounded.png";
import styles, { interFontDefinitions } from "./pdfStyles";
import { formatterDateTimeUS, formatterDollarUSNoDecimal, formatterPercentWithSign } from '../../../utils/formatters';
import { ReportTableData } from '../logic/reportTablesLogic';
import { getVarianceColor } from '../../../utils/variance';
import { IClientBrandingAsset } from "../constants";
import { ExecutiveSummaryGraphData } from '../logic/seriesGraphDataLogic';

export interface IExecutiveSummaryPDFProps {
    data: PropertyExecutiveSummaryData,
    charts: Map<SERIES_DATA_OPTION, string>,
    viewOnly: boolean,
    rawChartData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
}

export interface IMetricBlockValue {
    value: string;
    topLabel?: JSX.Element | string;
    bottomLabel?: JSX.Element | string;
}

export interface IMetricBlock {
    title: string;
    isSmallTitle?: boolean;
    values?: IMetricBlockValue[];
    text: JSX.Element | string;
}

export interface IExecutiveSummaryPDFExportProps {
    data: PropertyExecutiveSummaryData,
    charts: Map<SERIES_DATA_OPTION, string>,
    rawChartData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
    financialOverviewData: string[][][],
    revenueSummaryData: string[][][],
    payrollTableData: string[][][],
    clientBranding: IClientBrandingAsset,
    title_by_series_option: Record<string, string>,
    fullExecutiveSummaryStatementNOI: string,
    executiveSummaryStatementNOI: string,
    rentsStatementSummary: string,
    execSummaryMetricData: Record<string, IMetricBlock>,
}

export interface IExecutiveSummaryPDFExportWorkerProps extends Omit<IExecutiveSummaryPDFExportProps, 'execSummaryMetricData'> {
    execSummaryMetricData: string,
}

export function chunkArray(array: string[][], size: number) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}

export function execSummaryPDFExport(props: IExecutiveSummaryPDFExportProps) {
    const {
        data,
        rawChartData,
        clientBranding,
        charts,
        title_by_series_option,
        fullExecutiveSummaryStatementNOI,
        executiveSummaryStatementNOI,
        financialOverviewData,
        revenueSummaryData,
        payrollTableData,
        rentsStatementSummary,
        execSummaryMetricData,
    } = props;

    Font.register(interFontDefinitions);
    Font.registerHyphenationCallback((word) => {
        return [word];
    });

    function calculateFinancialTablePageLength(tables: ReportTableData[]): number {
        const availablePageHeight = 638;
        const tableHeaderHeight = 40;
        const tableRowHeight = 22;
        const tableBottomMargin = 10;

        let pagesNeeded = 1;
        let remainingPageHeight = availablePageHeight;

        for (const table of tables) {
            const tableHeight = tableHeaderHeight + (table.rows.length + 1) * tableRowHeight + tableBottomMargin;

            if (tableHeight > remainingPageHeight) {
                pagesNeeded++;
                remainingPageHeight = availablePageHeight;
            }

            remainingPageHeight -= tableHeight;
        }

        return pagesNeeded;
    }

    function buildTableOfContentsItems() {
        const financialTablesPageLength = calculateFinancialTablePageLength(data.reportTables);

        const toc = [
            ["Analysis", 2, "analysis"],
            ["Financial Tables", 3, "financial-tables"],
            ["Operational Metric Charts", financialTablesPageLength + 3, "ops-metric-charts"],
            ["Financial Overview", financialTablesPageLength + 3 + 2, "financial-overview"],
            ["Revenue Summary", financialTablesPageLength + 3 + 2 + financialOverviewData.length, "rev-summary"],
            ["Payroll", financialTablesPageLength + 3 + 2 + financialOverviewData.length + revenueSummaryData.length, "payroll"],
        ];

        return (
            toc.map(([title, page, anchor], i) =>
                <Link src={`#${anchor}`} style={styles.tocItem} key={i}>
                    <Text style={styles.tocTitle}>{title}</Text>
                    <Text style={styles.tocPageNumber}>{page}</Text>
                </Link>
            )
        );
    }

    const tableOfContents = (
        <Page size="LETTER" style={styles.page}>
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Table of Contents</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>

            {buildTableOfContentsItems()}
            {pageFooter()}
        </Page>
    );

    function pageFooter() {
        return (
            <View style={styles.pageFooter} fixed>
                <Image src={brand} style={styles.pageFooterLogo} />
                <Text style={styles.pageFooterTimestamp} render={({ pageNumber }) => {
                    const adjustedPageNumber = pageNumber - 1;
                    return adjustedPageNumber > 1 ? formatterDateTimeUS(new Date()) : " ";
                }} />
                <Text style={styles.pageFooterNumber} render={({ pageNumber }) => {
                    const adjustedPageNumber = pageNumber - 1;
                    return adjustedPageNumber > 1 ? adjustedPageNumber : " ";
                }} />
            </View>
        );
    }

    function renderFinancialsTable(rows: string[][]) {
        const columnCount = 18;
        const rowCount = rows.length;

        return (
            <View wrap={false} style={{ ...styles.cardWrapper, ...styles.cardWrapperFull }}>
                <View style={{ ...styles.cardBody, ...styles.cardBodyFlush }}>
                    {
                        rows.map((row, rowIndex) =>
                            <View style={styles.tableRow} key={`row-${rowIndex}`}>
                                {
                                    row.map((r, colIndex) => {
                                        let wrapperStyles = {};

                                        if (colIndex == 0) {
                                            wrapperStyles = styles.financialsTableGLCol;
                                        } else if (colIndex == 1) {
                                            wrapperStyles = styles.financialsTableNameCol;
                                        } else {
                                            wrapperStyles = styles.financialsTableDataCol;
                                        }

                                        if (colIndex == columnCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalCol };
                                        }

                                        if (rowIndex == 0) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.financialsTableHeaderRow };
                                        }

                                        if (rowIndex == rowCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalRow };
                                        }

                                        if (row[0] == "") {
                                            wrapperStyles = { ...wrapperStyles, ...styles.financialsTableSummaryRow };
                                        }

                                        // Dec col, to show thick border before totals
                                        if (colIndex == 13) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.financialsTableThickBorderRight };
                                        }

                                        return (
                                            <View style={{ ...styles.tableCellWrapper, ...wrapperStyles }} key={`row-${rowIndex}-cell-${colIndex}`}>
                                                <Text style={styles.financialsTableCell}>{r}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        )
                    }
                </View>
            </View>
        );
    }

    function renderRevenueTable(rows: string[][]) {
        const firstRow = rows[0];

        if (!firstRow) {
            return <></>;
        }

        const columnCount = firstRow.length;
        const rowCount = rows.length;

        return (
            <View wrap={false} style={{ ...styles.cardWrapper, ...styles.cardWrapperFull }}>
                <View style={{ ...styles.cardBody, ...styles.cardBodyFlush }}>
                    {
                        rows.map((row, rowIndex) =>
                            <View style={styles.tableRow} key={`row-${rowIndex}`}>
                                {
                                    row.map((text, colIndex) => {
                                        let wrapperStyles = {};

                                        if (colIndex == 0) {
                                            wrapperStyles = styles.financialsTableNameCol;
                                        } else {
                                            wrapperStyles = styles.revenueTableDataCol;
                                        }

                                        if (colIndex == columnCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalCol };
                                        }

                                        if (rowIndex == 0) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.financialsTableHeaderRow };
                                        }

                                        if (rowIndex == rowCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalRow };
                                        }

                                        if (row.length == 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.financialsTableSummaryRow };
                                        }

                                        return (
                                            <View style={{ ...styles.tableCellWrapper, ...wrapperStyles }} key={`row-${rowIndex}-cell-${colIndex}`}>
                                                <Text style={styles.revenueTableCell}>{text.length == 0 ? " " : text}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        )
                    }
                </View>
            </View>
        );
    }

    function buildMetricBlock(metricData: IMetricBlock | undefined, metricType: string) {
        if (!metricData) {
            return <></>;
        }

        return (
            <View style={styles.metricBlock} key={metricType}>
                <Text style={{ ...styles.title, ...styles.metricBlockTitle }}>{metricData.title}</Text>
                <View style={styles.metricBlockValues}>
                    {metricData.values && metricData.values.map((v, i) => {
                        return (
                            <View key={i} style={styles.metricBlockValueWrapper}>
                                <Text style={styles.metricBlockTopLabel}>{v.topLabel}</Text>
                                <Text style={styles.metricBlockValue}>{v.value}</Text>
                                {
                                    v.bottomLabel &&
                                    <Text style={styles.metricBlockBottomLabel}>{v.bottomLabel}</Text>
                                }
                            </View>
                        );
                    })}
                </View>
                <Text style={styles.metricBlockText}>{metricData.text}</Text>
            </View>
        );
    }

    function buildChartView(type: SERIES_DATA_OPTION, chartData: string) {
        const chartName = title_by_series_option[type];
        const chartMetricData = rawChartData.get(type);

        if (!chartName || !chartMetricData) {
            return <></>;
        }

        const { comparisonItemOne, comparisonItemTwo, varianceAmount, variancePercent } = chartMetricData.summary;

        return (
            <View wrap={false} style={styles.cardWrapper} key={chartName}>
                <View style={styles.cardHeader}>
                    <Text style={styles.cardHeaderTitle}>{chartName}</Text>
                    <Text style={styles.cardHeaderSubtitle}>{data.property.reforecastYear} RFCST vs {data.property.budgetYear} BDGT</Text>
                </View>
                <View style={styles.cardBody}>
                    <View style={styles.chartBodyHeader}>
                        <View style={styles.chartBodyMetric}>
                            <Text style={styles.chartBodyMetricLabel}>{comparisonItemOne.label}</Text>
                            <Text style={styles.chartBodyMetricValue}>{comparisonItemOne.value}</Text>
                        </View>
                        <View style={styles.chartBodyMetric}>
                            <Text style={styles.chartBodyMetricLabel}>{comparisonItemTwo.label}</Text>
                            <Text style={styles.chartBodyMetricValue}>{comparisonItemTwo.value}</Text>
                        </View>
                        <View style={styles.chartBodyMetric}>
                            <Text style={styles.chartBodyMetricLabel}>{varianceAmount.label}</Text>
                            <Text style={styles.chartBodyMetricValue}>{varianceAmount.value}</Text>
                        </View>
                        <View style={styles.chartBodyMetric}>
                            <Text style={styles.chartBodyMetricLabel}>{variancePercent.label}</Text>
                            <Text style={styles.chartBodyMetricValue}>{variancePercent.value}</Text>
                        </View>
                    </View>
                    <Image src={chartData} />
                </View>
            </View>
        )
    }

    const coverPage = (
        <Page size="LETTER" style={styles.coverPage}>
            <View style={styles.overlayContainer}>
                <Image src={`/${clientBranding.banner}`} style={styles.backgroundImage} />
                <View style={{ ...styles.colorOverlay, backgroundColor: clientBranding.colorPrimary }} />
                <View style={{ ...styles.coverPageBanner, backgroundColor: clientBranding.colorPrimary }}>
                    <Text style={styles.propertyName}>{data.property.name}</Text>
                    <Text style={styles.presentedBy}>{data.property.budgetYear} Operating Budget</Text>
                    <Text style={styles.presentedBy}>Presented by {data.user.clientName}</Text>
                    <View style={{ ...styles.brandColorBand, backgroundColor: clientBranding.colorSecondary }}>
                        {/* TODO:bowman this is a switch on our special tri-color asset living branding, but should be revisited after deadline */}
                        {
                            data.user.clientId == "6de1678f-59af-49b1-accd-4b76b612a160" || data.user.clientId == "0b1f3821-0616-4fed-9ca6-bfa9ecfd8f3c" ?
                                <>
                                    <View style={{ ...styles.colorBar, ...styles.colorBarBlue }}></View>
                                    <View style={{ ...styles.colorBar, ...styles.colorBarGreen }}></View>
                                    <View style={{ ...styles.colorBar, ...styles.colorBarOrange }}></View>
                                </>
                                : <></>
                        }
                    </View>
                </View>
            </View>
            <View style={styles.coverFooterBranding}>
                <Image src={brandLight} style={styles.footerVizLogo} />
                {
                    clientBranding.logo && <>
                        <Image src={addRounded} style={styles.footerBrandJoinIcon} />
                        <Image src={clientBranding.logo} style={styles.footerPartnerLogoAssetLiving} />
                    </>
                }
            </View>
        </Page>
    );

    const analysis = (
        <Page id="analysis" size="LETTER" style={styles.page}>
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Analysis</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>
            <View style={{ marginBottom: 24 }}>
                <Text style={{ ...styles.title, ...styles.titleSmall }}>Executive Summary</Text>
                <Text style={styles.paragraph}>
                    {fullExecutiveSummaryStatementNOI}
                </Text>
            </View>

            <View style={{ marginBottom: 24 }}>
                <Text style={{ ...styles.title, ...styles.titleSmall }}>Rent Summary</Text>
                <Text style={styles.paragraph}>
                    {rentsStatementSummary}
                </Text>
            </View>

            <View style={styles.metricBlockRow}>
                {buildMetricBlock(execSummaryMetricData['mktRent'], 'mktRent')}
                {buildMetricBlock(execSummaryMetricData['occupancy'], 'occupancy')}
            </View>

            <View style={styles.metricBlockRow}>
                {buildMetricBlock(execSummaryMetricData['resTurn'], 'resTurn')}
                {buildMetricBlock(execSummaryMetricData['tradeouts'], 'tradeouts')}
            </View>

            <View>
                <Text style={{ ...styles.title, ...styles.titleSmall }}>Financial Summary</Text>
                <Text style={styles.paragraph}>
                    {executiveSummaryStatementNOI}
                </Text>
            </View>

            {pageFooter()}
        </Page>
    );

    const opMetricCharts = (
        <Page size="LETTER" style={styles.page}>
            <View id="ops-metric-charts" />
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Operational Metric Charts</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>

            <View style={styles.chartsPageWrapper}>
                {Array.from(charts.entries()).map(chart => buildChartView(chart[0], chart[1]))}
            </View>

            {pageFooter()}
        </Page>
    );

    const revenueTables = (
        <Page size="LETTER" style={styles.page} orientation="landscape">
            <View id="rev-summary" />
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Revenue Summary</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>

            {
                revenueSummaryData.map((rowData) => renderRevenueTable(rowData))
            }

            {pageFooter()}
        </Page>
    );

    function buildReportTableView(title: string, subtitle: string, tableData: ReportTableData) {
        return (
            <View wrap={false} style={{ ...styles.cardWrapper, ...styles.cardWrapperFull }}>
                <View style={styles.cardHeader}>
                    <Text style={styles.cardHeaderTitle}>{title}</Text>
                    <Text style={styles.cardHeaderSubtitle}>{subtitle}</Text>
                </View>

                <View style={{ ...styles.cardBody, ...styles.cardBodyFlush }}>
                    <View style={styles.tableRow}>
                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableNameCol, ...styles.headerRow }}>
                            <Text style={styles.tableCell}>Description</Text>
                        </View>

                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.headerRow }}>
                            <Text style={styles.tableCell}>{data.property.reforecastYear} Total</Text>
                        </View>

                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.headerRow }}>
                            <Text style={styles.tableCell}>{data.property.budgetYear} Total</Text>
                        </View>

                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.headerRow }}>
                            <Text style={styles.tableCell}>Growth $</Text>
                        </View>

                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.headerRow }}>
                            <Text style={styles.tableCell}>Growth %</Text>
                        </View>

                        <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.headerRow, ...styles.finalCol }}>
                            <Text style={styles.tableCell}>{data.property.budgetYear}/Unit</Text>
                        </View>
                    </View>

                    {
                        tableData.rows.sortBy('order').map((row, rowIndex) => {
                            let wrapperStyles = {};

                            if (rowIndex == tableData.rows.length - 1) {
                                wrapperStyles = { ...wrapperStyles, ...styles.finalRow };
                            }

                            return (
                                <View style={styles.tableRow} key={`row-${rowIndex}`}>
                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableNameCol, ...wrapperStyles }}>
                                        <Text style={styles.tableCell}>{row.name}</Text>
                                    </View>

                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...wrapperStyles }}>
                                        <Text style={styles.tableCell}>{row.reforecastTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.reforecastTotal)}</Text>
                                    </View>

                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...wrapperStyles }}>
                                        <Text style={styles.tableCell}>{row.budgetTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetTotal)}</Text>
                                    </View>

                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...wrapperStyles }}>
                                        <Text style={{
                                            ...styles.tableCell,
                                            color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                                        }}>
                                            {row.varianceAmount === null ? "-" : formatterDollarUSNoDecimal.format(row.varianceAmount)}
                                        </Text>
                                    </View>

                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...wrapperStyles }}>
                                        <Text style={{
                                            ...styles.tableCell,
                                            color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                                        }}>
                                            {row.variancePercent === null ? "-" : formatterPercentWithSign.format(row.variancePercent)}
                                        </Text>
                                    </View>

                                    <View style={{ ...styles.tableCellWrapper, ...styles.reportTableDataCol, ...styles.finalCol, ...wrapperStyles }}>
                                        <Text style={styles.tableCell}>{row.budgetPerUnit === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetPerUnit)}</Text>
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        );
    }

    function renderPayrollTable(rows: string[][]) {
        const columnCount = 4;
        const rowCount = rows.length;

        return (
            <View wrap={false} style={{ ...styles.cardWrapper, ...styles.cardWrapperFull }}>
                <View style={{ ...styles.cardBody, ...styles.cardBodyFlush }}>
                    {
                        rows.map((row, rowIndex) =>
                            <View style={styles.tableRow} key={`row-${rowIndex}`}>
                                {
                                    row.map((r, colIndex) => {
                                        let wrapperStyles = {};
                                        let cellStyles = {};

                                        if (colIndex == 0) {
                                            wrapperStyles = styles.reportTableNameCol;
                                        } else if (colIndex > 0) {
                                            wrapperStyles = styles.payrollTableDataCol;
                                        }

                                        if (colIndex == columnCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalCol };
                                        }

                                        if (rowIndex == 0) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.headerRow };
                                        }

                                        if (rowIndex == rowCount - 1) {
                                            wrapperStyles = { ...wrapperStyles, ...styles.finalRow };
                                        }

                                        return (
                                            <View style={{ ...styles.tableCellWrapper, ...wrapperStyles }} key={`row-${rowIndex}-cell-${colIndex}`}>
                                                <Text style={{ ...styles.tableCell, ...cellStyles }}>{r}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        )
                    }
                </View>
            </View>
        );
    }

    const financialTables = (
        <Page size="LETTER" style={styles.page}>
            <View id="financial-tables" />
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Financial Tables</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>

            {data.reportTables.map((reportTable, i) =>
                <View key={`reportTable-${i}`}>
                    {buildReportTableView(reportTable.name, `${data.property.budgetYear % 100} BDGT`, reportTable)}
                </View>
            )}

            {pageFooter()}
        </Page>
    );

    const financialOverview = (
        <Page size="LETTER" style={styles.page} orientation="landscape">
            <View id="financial-overview" />
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Financial Overview</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>
            {
                financialOverviewData.map((rowData) => renderFinancialsTable(rowData))
            }

            {pageFooter()}
        </Page>
    );

    const payrollTables = (
        <Page size="LETTER" style={styles.page} orientation="landscape">
            <View id="payroll" />
            <View style={styles.pageTitleWrapper} fixed>
                <Text style={styles.pageTitle}>Payroll</Text>
                <Text style={styles.pageTitlePropertyName}>{data.property.name}</Text>
            </View>

            {
                payrollTableData.map((rowData) => renderPayrollTable(rowData))
            }

            {pageFooter()}
        </Page>
    );

    return (
        <Document>
            {coverPage}
            {tableOfContents}
            {analysis}
            {financialTables}
            {opMetricCharts}
            {financialOverview}
            {revenueTables}
            {payrollTables}
        </Document>
    );
}
